.about
{
  .qrcode
  {
    margin: 40px 0;
    padding: 5px;
    padding-bottom: 0px;
    background-color: #ffffff;
  }
  .description
  {
    max-width: 250px;
    text-shadow: 1px 2px 3px #303030;
  }
}
