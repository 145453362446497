.outer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.3);
}

.inner-container {
  padding: 0;
  width: 720px;
  height: 673px;
  max-height: 90vh;
  position: relative;
}

.app {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 381px;
  height: 100%;
  border-radius: 20px;
  overflow: clip;
  background-color: #FFFFFF;
  border: 3px solid #555555;
}

.about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 415px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

@media only screen and (max-width: 960px) {

  #root {
    background: none !important;
  }

  .inner-container {
    padding: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: relative;
  }

  .app {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
    height: 100%;
    border-radius: 0;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }

  .about {
    display: none;
  }

  .github-fork-ribbon {
    display: none;
  }

  header,
  .menu-header {
    position: fixed;
  }

  .menu-content {
    position: fixed;
    height: 100%;
  }

  a[href^="tel:"]{
    pointer-events: auto;
  }
}
