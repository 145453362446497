#guide-panel {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 350px;
  height: 100%;
  top: calc(-100% + 50px);
  left: 0;
  right: 0;
  margin: auto;

  #guide-panel-check {
    display: none;
  }

  .guide-panel-content {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #fff;
    transition: .5s;

    a {
      padding: 15px 20px 0 20px;
    }

    .guide-panel-button {
      position: absolute;
      bottom: -19px;
      z-index: 2;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #fff;
      width: 100%;
      height: 30px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 100%;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;

      .arrow {
        border: solid var(--header-color);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        margin-top: 7px;
        margin-bottom: 7px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transition: .5s;
      }
    }

    &> a {
      position: absolute;
      bottom: 13px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
}

#guide-panel-check:checked~.guide-panel-content {
  // top: 280px;
  .arrow {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

#banner {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  width: 80%;
  max-width: 350px;
  margin: auto;
  border-radius: 30px;
  padding: 15px 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

#guide-panel a {
  text-decoration: none;
  color: #4C4C4C;
}

.banner-title,
.list-title {
  font-weight: 700;
  font-size: 16px;
  margin-right: 25px;
}

.banner-title {
  font-size: 18px;
}
