:root {
  --header-color: #007AC3;
}

#root {
  background: url(../public/background.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
}

* {
  font-family: 'Hiragino Kaku Gothic Pro','ヒラギノ角ゴ Pro W3','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  color: var(--header-color);
}

// PC閲覧時には 電話番号リンクを無効化
a[href^="tel:"]{
  pointer-events: none;
}

.branding
{
  text-align: center;
  .logo
  {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 72px;
    max-width: 280px;
    line-height: 0.9;
    text-shadow: 1px 2px 3px #808080;
    margin: auto;
  }
  .link
  {
    font-size: 1.2rem;
  }
  .image
  {
    width: 180px;
    height: 180px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
}

header,
.menu-header {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header-color);
  color: #ffffff;
}

.header-title,
.menu-title {
  font-weight: 700;
}

.menu-subtitle {
  font-size: 14px;
}

.menu-btn {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: var(--header-color);
}

.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: '';
  display: block;
  height: 2px;
  width: 20px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}

.menu-btn span:before {
  bottom: 7px;
}

.menu-btn span:after {
  top: 7px;
}

#menu-btn-check:checked~.menu-btn span {
  background-color: rgba(255, 255, 255, 0);
}

#menu-btn-check:checked~.menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}

#menu-btn-check:checked~.menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

#menu-btn-check {
  display: none;
}

.hamburger-menu {
  height: 100%;
  width: 100%;
  position: absolute;
}

.menu-header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.menu-content {
  width: 100%;
  height: 673px;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 90;
  background-color: var(--header-color);
  transition: all 0.5s;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.menu-content ul {
  padding: 50px 0 10px 20px;
  flex-grow: 1;
}

.menu-content ul li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  list-style: none;
}

.menu-content ul li summary {
  list-style: none;
}

.menu-content ul li summary::-webkit-details-marker {
  display:none;
}

.menu-content ul li details p {
  font-size: 12px;
  font-weight: 400;
}

.menu-content ul li details {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  box-sizing: border-box;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px 12px 0;
  position: relative;
}

.menu-content ul li details::before {
  content: "";
  width: 7px;
  height: 7px;
  border-top: solid 2px rgba(255, 255, 255, 0.5);
  border-right: solid 2px rgba(255, 255, 255, 0.5);
  transform: rotate(45deg);
  position: absolute;
  right: 30px;
  top: 16px;
}

.menu-content ul li details[open]::before {
  transform: rotate(135deg);
}

.menu-content details a {
  color: #ffffff;
}

.menu-content details ol {
  padding-left: 1em;
}

.menu-content details ol li {
  font-size: 12px;
  font-weight: normal;
  list-style-position: inside;
  padding-left: 1em;
  text-indent: -1em;
  border: none;
  list-style-type: decimal;
}

.menu-content details ul {
  padding: 10px 0 0 1em;
  list-style-position: inside;
}

.menu-content details ul li {
  font-size: 12px;
  font-weight: normal;
  list-style-position: inside!important;
  list-style: disc;
  padding-left: 1em;
  text-indent: -1em;
  border: none;
}

#menu-btn-check:checked~.menu-content {
  left: 0;
}

.menu-footer {
  width: 100%;
  color: black;
  background-color: #ffffff;
}

.menu-footer .social-icons {
  padding: 15px 20px
}

.menu-footer .social-icons .social-icons-title {
  color: var(--header-color);
  font-weight: 700;
  font-size: 12px;
}

.menu-footer .social-icons ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 10px auto;
}

.menu-footer .social-icons ul li {
  margin-right: 25px;
}

.menu-footer .social-icons ul img {
  width: 40px;
}

.menu-footer .divider {
  border: 1px solid rgba(0, 122, 195, 0.3);
  margin: auto 0;
  width: 255px;
}

.menu-footer .brand {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 20px;
}

.menu-footer .brand .brand-title span {
  font-size: 10px;
  line-height: 1.9;
}

.menu-footer .brand .brand-title p {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #1B79C4;
  line-height: 22px;
}

.menu-footer .brand .logo {
  width: 50px;
  margin-right: 10px;
}

.menu-footer .copy-right {
  font-size: 10px;
  padding: 0 20px 20px;
}

#map {
  margin: 50px 0 0 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.list-title {
  word-break: keep-all;
}

.banner-period,
.list-period {
  background-color: #007AC3;
  color: #ffffff;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 14px;
  margin: 15px 0;
  width: fit-content;
}

.banner-description {
  font-size: 14px;
}

#banner-close,
#list-close {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  top: 27px;
  right: 10px;
  padding: 5px;
  cursor: pointer;
}

#list-close {
  top: 27px;
  right: 30px;
  display: none;
  z-index: 5;
}

.open #list-close {
  display: flex;
}

#banner-close span,
#banner-close span:before,
#banner-close span:after,
#list-close span,
#list-close span:before,
#list-close span:after {
  content: '';
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 3px;
  background-color: var(--header-color);
  position: absolute;
}

#banner-close span:before,
#list-close span:before {
  bottom: 0;
  transform: rotate(45deg);
}

#banner-close span:after,
#list-close span:after {
  top: 0;
  transform: rotate(-45deg);
}

#banner-close span,
#list-close span {
  background-color: rgba(255, 255, 255, 0);
}

#list-return {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-top: 30px;
  cursor: pointer;
  color: var(--header-color);
  font-size: 14px;
  font-weight: 700;
}

#list-return img {
  width: 15px;
  height: 15px;
}

#list {
  position: absolute;
  width: 100%;
  height: 84px;
  z-index: 3;
  background-color: #ffffff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  bottom: 0;
  transition: transform 0.4s ease;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

#list.open {
  height: 60%;
  cursor: auto;
}

#list::-webkit-scrollbar {
  display: none;
}

#list::before,
.list-header::before {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  width: 40px;
  height: 5px;
  border-radius: 4px;
  background-color: rgba(32, 32, 32, 0.2);
  z-index: 2;
}

#list-content {
  padding: 30px;
}

#list-content ul {
  list-style: none;
  padding-left: 0;
}

#list-content ul li {
  font-size: 14px;
  border-bottom: 1px solid #E1E1E1;
  padding: 5px 0;
  display: flex;
  align-items: center;
}

#list-content ul li:first-child {
  border-top: 1px solid #E1E1E1;
}

#list-content ul li i {
  color: #ffffff;
  background-color: var(--header-color);
  border-radius: 15px;
  padding: 5px;
  margin-right: 5px;
  font-size: 12px;
}

#list-content ul li img {
  width: 20px;
  margin-right: 10px;
}

#list-content ul li.display-block {
  display: block;
}

#list .list-section {
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
}

#search-result .icon-container:last-child,
#new-events .icon-container:last-child {
  margin-left: 100px;
}

.list-label {
  color: var(--header-color);
}

.list-description {
  margin-bottom: 10px;
}

.list-price-description {
  font-size: 10px;
}

.list-header {
  display: flex;
  align-items: center;
  z-index: 3;
}

.list-header .title {
  font-weight: 600;
  font-size: 16px;
}

.fa-list {
  color: var(--header-color);
  margin-right: 10px;
  font-weight: 900;
  font-size: 20px;
}

.list-footer-content {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.list-footer-content a {
  text-decoration: none;
}

.list-footer-content .box {
  border: 1px solid var(--header-color);
  padding: 10px 11px;
  margin-right: 10px;
  color: var(--header-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.list-footer-content .box img {
  margin-right: 5px;
  width: 17px;
}

.list-footer-content .box.walk {
  height: 26px;
  margin-right: 18px;
}

.list-footer-content .circle {
  border: 1px solid var(--header-color);
  padding: 9px 10px;
  color: var(--header-color);
  border-radius: 60px;
  display: flex;
  align-items: center;
}

.list-footer-content .circle img {
  width: 20px;
}

#search {
  text-align: center;
}

#search .content-title {
  font-weight: 700;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#search .content-title img {
  margin-right: 10px;
  width: 20px;
}

.checkbox-group {
  display: flex;
  justify-content: center;
  margin: 20px 10px;
  background-color: #F2F2F2;
  border-radius: 10px;
}

.checkbox-group .item {
  width: 100px;
}

.checkbox-group .checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.checkbox-group .checkbox+label {
  padding: 7px;
  cursor: pointer;
  color: #555;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
}

.checkbox-group .item:first-of-type .checkbox+label {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid #CCC;
}

.checkbox-group .item:last-of-type .checkbox+label {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid #CCC;
}

.checkbox-group .checkbox:checked+label {
  background-color: var(--header-color);
  color: #FFF;
}

#search {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
}

#search #keyword {
  background-color: #F2F2F2;
  border: none;
  padding: 10px;
  padding-left: 50px;
  border-radius: 20px;
  width: 200px;
  font-size: 16px;
}

#search #submit-button {
  padding: 13px 9px;
  border-radius: 10px;
  border: none;
  background-color: var(--header-color);
  color: #ffffff;
  width: 200px;
  font-size: 16px;
  margin: auto;
  font-weight: 700;
  position: relative;
}

#search #submit-button img {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
}

.keyword-container {
  position: relative;
  margin-bottom: 20px;
}

.keyword-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 35px;
  height: 16px;
}

#search-result,
#new-events
{
  margin: -30px;
}

#search-result .list-header,
#new-events .list-header
{
  padding: 30px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  border-bottom: 1px solid var(--header-color);
}

#search-result .list-header {
  flex-direction: column;
  align-items: flex-start;
}

#new-events .list-header {
  flex-direction: row;
  align-items: center;
}

#search-result .list-header img,
#new-events .list-header img
{
  width: 40px;
  margin-right: 15px;
}

#new-events .list-header img.list-logo
{
  width: 20px;
  margin-right: 15px;
}

#search-result .list-header .list-header-inner,
#new-events .list-header .list-header-inner
{
  display: flex;
}

#search-result .list-header-title-main,
#new-events .list-header-title-main
{
  font-weight: 700;
  font-size: 18px;
}

#search-result .list-sub-title,
#search-result .list-header-title-sub,
#new-events .list-sub-title,
#new-events .list-header-title-sub
{
  font-size: 12px;
  color: #808080;
}

#search-result .list-section,
#new-events .list-section
{
  border-top: 1px solid var(--header-color);
}

#new-events .list-section:nth-child(2),
#search-result .list-section:nth-child(2)
{
  border-top: none;
}

#list-content.search-result {
  padding: 0;
}

#search-result .list-section,
#new-events .list-section {
  padding: 20px 30px;
}

#search-result .list-section ul,
#new-events .list-section ul {

  margin: 0;
}

#new-events .category-content {
  display: flex;
  margin: 20px 0;
}

#search-result .category-label,
#new-events .category-label {
  background-color: #E5E5E5;
  font-size: 12px;
  padding: 4px 20px;
  border-radius: 20px;
  margin-right: 10px;
}

#search-result .category-label.active,
#new-events .category-label.active {

  border: 1px solid var(--header-color);
  color: var(--header-color);
  background-color: #ffffff;
}

.maplibregl-ctrl-group:not(:empty) {
  box-shadow: none!important;
  background-color: transparent;
}

.maplibregl-ctrl-group button:focus:only-child {
  border-radius: 60px!important;
}

button.maplibregl-ctrl-geolocate {
  width: 60px!important;
  height: 60px!important;
  background-color: #ffffffff!important;
  border-radius: 60px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.maplibregl-ctrl-geolocate .maplibregl-ctrl-icon {
  background-image: url('./point.svg')!important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 70%!important;
  width: 70%!important;
  margin: auto;
  position: absolute;
  top: 10px;
  right: 11px;
}

#search-button {
  width: 40px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.maplibregl-ctrl-bottom-right {
  bottom: 84px !important;
}

